<template>
  <div>
    Dashboard
    <a href="https://linwuaiwo.cn">回到首页</a>
  </div>
</template>


<script>
export default {
  name : 'Dashboard'
}
</script>


<style scoped>

</style>